:root {
    /*Margins & Paddings*/
    --padding: 1em;
    --margin: 1em;
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em;  /* must NOT be a percentage value */
    
     /*Breakpoints*/
    --breakpoint-m: 800px;
    --breakpoint-s: 768px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
    
     /*General*/
    --container-max-width: 990px;
    --fixed-header-height: 80px;
    --base-font-family: Lato;
    --alternative-font-family: OpenSans;
    --topmenu-font-family: AlegreyaSans;
    --heading-font-family: Alegreya;
    
    /* change settings for headings */
    --header-bg-color: white;
    --header-padding: 1.5em 0 0;
    
    /* change colors for topmenu */
    --topmenu-bg: var(--link-color);
    --topmenu-color: black;
    --topmenu-hover-color: #F966CD;
    --topmenu-li-a-padding: 0 15px;

    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 16px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;
    
    /*Colors & Backgrounds*/
    --base-color: #80D321;
    --text-color: #444;
    --heading-color: var(--base-color);
    --link-color: #222;
    --link-hover-color: #000;
    --alternative-color: #ff0000;
}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.2.css";
@import "/cssmodules/magnific-popup.css";
@import "/fontmodules/OpenSans.css";
@import "/fontmodules/Lato.css";
@import "/fontmodules/Alegreya.css";
@import "/fontmodules/AlegreyaSans.css";




/*@import "/cssmodules/animate.css";*/


[style*="font"] {
    font-family: inherit !important;
    font-size: inherit !important;
}

/* General bg-colors
---------------------------------------------------------*/
.breadcrumbs {
    font-size:0.8rem;
}

.breadcrumbs, .art-content >* {
    padding:0 0.5em !important;
}

.right-col h3 {
    margin-top:0;
}

.maincolor {
    background-color: var(--link-color);
}

.white {
    background-color:white;
}

.lightgray {
    background-color:#eee;
}

.darkgray {
    background-color:#222;
}


/* Header
---------------------------------------------------------*/
header {
    background:var(--header-bg-color);
    text-transform:none;
    padding:var(--header-padding);
}

header .container {
    padding:0 0.5em;
}

header a {
    color:black;
}

.topout {
    border-bottom:#eee solid 2px;
}

.topout .firstname {
    color:var(--base-color);
    font-weight:700;
    letter-spacing:-1px;
}

.topout .search form {
    border:#eee solid 1px;
}

.topout .search button {
    text-align:right;
}
.topout .search button svg.fa-icon {
    fill: var(--base-color);
}

.topout .search button, .topout .search input {
    background:transparent;
    border:none;
    padding:0;
    margin:0.5em;
}

.topout .search button[type=submit]:hover {
    border:none;
    background:transparent;
}

.topout .topheading span {
    display:block;
    text-transform:uppercase;
    margin-top:0.5em;
    font-size: calc(1 * var(--scale-factor) - 0.4rem);
    font-family: var(--heading-font-family);
    color:var(--base-color);
}

.topmenu {
    margin-top:1em;
}

.menu li {
    margin:0;
}

.menu li a {
    padding:var(--topmenu-li-a-padding);
    font-family: var(--topmenu-font-family);
    font-size:1.1rem;
    color:var(--topmenu-color);
    font-weight:700;
    text-transform:uppercase;
}

.menu li a:hover, .menu .active a {
    background: transparent;
    color:var(--topmenu-hover-color);
}

.menu >.first a {
    padding-left:0;
}

.menu .dropdown ul {
    background:var(--topmenu-bg);
    z-index:100;
}

.menu li:hover ul {
    margin-left:0;
}

.menu .dropdown li a {
    padding:10px 15px;
    color:#000;
    border-right:none;
}

.menu .dropdown li a:hover, .menu .dropdown .active a {
    background:var(--topmenu-bg);
    color:var(--topmenu-color);
}

.menu .sub-menu-items li a {
    margin:0;
}

.logo {
    padding:0;
}

.logo a {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
    text-transform:uppercase;
}


/* bildspel
---------------------------------------------------------*/

.slick {
    position: relative;
    margin-bottom: 0;
}

.slick-track {
    display: flex;
    flex-direction: row;
}   

.slick, .slick-slide, .slick-substitute > div {
    height:100%;
    min-height: 45vh;
}

.slick-slide, .slick-substitute > div {
    background-size: cover;
    background-position: center center;
}

.slick-slider {
    margin:0 auto;
    position: relative;
}

.slick-slide {
    background-size:cover;
    background-position: center center;
    display: flex !important;
    align-items: flex-end;
    justify-content: center;
} 

.slick-title {
    text-align:center;
    color:white;
    width:100%;
    background:rgba(51, 51, 51, 0.7);
    padding:0.5em 0;
}

.slick-title h2 {
    display:block;
    color:white;
    font-weight:700;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    text-transform:uppercase;
    margin:0;

}

.slick-prev, .slick-next {
    width:auto;
    height:auto;
}

.slick-next {
    right:10px;
    z-index:100;
}

.slick-prev {
    left:10px;
    z-index:100;
}

.slick-next, .slick-prev {
    opacity:1;
}

.slick-next:hover, .slick-prev:hover {
    border:none;
}

.slick-next:before, .slick-prev:before {
    transition: 0.8s ease;
    -o-transition: 0.8s ease;
    -webkit-transition: 0.8s ease;
   background:rgba(51,51,51,.7);
    padding:1.5em;
    font-family: Roboto;
    line-height:normal;
}

.slick-next:hover:before, .slick-prev:hover:before {
    background:white;
    color:black;
}

.slick-next:before {
    content:"»";
}

.slick-prev:before {
    content:"«";
}

.slick-dots {
    bottom:0px;
}

.slick-dots li button:before {
    font-size:0rem;
    opacity:1.0;
    color: var(--alternative-color);
    width:40px;
    height:8px;
}

.slick-dots li.slick-active button:before {
    background:var(--alternative-color);
    display:block;
}

.slick-dots li {
    border:var(--alternative-color) solid 1px;
    border-radius:0%;
    margin:0 2px;
    width:40px;
    height:8px;
}

/* Content-startpage
---------------------------------------------------------*/

/* startboxar */


.intro-content {
    border-bottom:#eee solid 2px;
}

.startbox-wrapper h2 {
    text-transform:uppercase;
}

.startbox a {
    text-decoration:none;
}

.startbox-wrapper {
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

.startbox .block-content {
    margin-bottom:1em;
}

/*.startbox .box-1 > div {
    margin-left:0;
}

.startbox .box-2 > div {
    margin:1em 0;
}

.startbox .box-3 > div {
    margin-right:0;
}*/

.newsletter-body p:first-of-type {
    margin-top:0;
}


/* block-3 */

.block-3 {
    padding:1em 0;
}

/*.block-3 .newsletter {
    margin-left:0;
}

.start-content {
    border-left:#eee solid 2px;
}*/

.start-content > div {
    padding:0 0 0 1em;
}

/* articles, blog
---------------------------------------------------------*/

.blog-img-gallery {
    border-top:#eee solid 2px;
    padding-top:1.5em;
}

.blog-img-gallery > div img{
    box-shadow:0 0 0 2px #eee;
}

.blog-img a, .blog-img-gallery a {
    display:block;
    position:relative;
}

.blog-img a:hover:after, .blog-img-gallery a:hover:after {
    position:absolute;
    top:45%;
    left:45%;
    content: "🔗";
    color:white;
    background:#F966CD;
    padding:1em;
}

.magnific:hover:after {
    content:"" !important;
    background:transparent !important;
    padding:0 !important;
}

.itemextrainfo {
    font-size:0.8rem;
    margin-bottom:1em;
}

.readmore {
    margin-top:1em;
}
.blog-img {
    margin-top:1.5em;
}

.taggs {
    text-decoration:none;
}

.taggs:hover {
    text-decoration:underline;
}

.art-content {
box-shadow: 2px 0px 0px 0px #eaeaea;
    margin-bottom:1.5em;
}

.newsitemwrapper {
    margin-bottom:1.5em;
    margin-right:1.5em;
    padding:0 0 1.5em 0;
    border-bottom:#eaeaea solid 1px;
}

.art-cat {
    font-size:0.8rem;
    margin-top:0.5em; 
}

.newsitemwrapper-inner h2 a {
    color:inherit;
    text-decoration:none;
}

.right-col h3 {
    text-transform:uppercase;
}

.right-col ul {
    padding-left:1em;
}

.right-col li {
    padding-bottom:0.5em;
}

.pagination {
}

.pagination ul {
    float: left;
    clear: both;
    display: block;
    margin: 8px 0;
    padding: 0;
    border: 1px solid #DDD;
}

.pagination ul li {
    float: left;
    list-style-type: none;
    border-right: 1px solid #DDD;
    padding: 4px 6px;
}

.pagination ul li:last-child {
    border-right: none;
}

.pagination ul li.active {
    font-weight: bold;
    background-color: #f966cd;
}

.pagination ul li a {
    text-decoration: none;
}

.article-items {
    margin-bottom:1.5em;
    padding-bottom:1.5em;
    border-bottom:#eee solid 2px;
}

.article-items h2 a {
    text-decoration:none;
}

/** Social Button CSS **/

.share-btn {
    display: inline-block;
    color: #ffffff;
    border: none;
    padding: 0.5em;
    width: 4em;
    outline: none;
    text-align: center;
    text-decoration:none;
    border-left:#fff  solid 2px;   
}

.share-btn:hover, .share-btn:hover svg.fa-icon {
    opacity:0.8;
    color:white;
}

.share-btn:active {
  position: relative;
  top: 2px;
  box-shadow: none;
  color: #e2e2e2;
  outline: none; 
}

.share-btn svg.fa-icon {
    fill: #fff;
}

.share-btn span {
    display:inline-block;
}

.share-btn.twitter     { background: #55acee; }
.share-btn.google-plus { background: #dd4b39; }
.share-btn.facebook    { background: #3B5998; }
.share-btn.linkedin    { background: #4875B4; }
.share-btn.email       { background: #444444; }
    

/* Content
---------------------------------------------------------*/

.contact-block {
    box-shadow: 0px 0px 0px 1px #ececec;
}

.contact-block-l, .contact-block-2 {
    padding: var(--padding);
}

.contact-block-2 > div {
    padding:1.5em 0;
}

.contact-block-2 .contactinfo p {
    margin:0;
}

.contact-block-2>div a {
    text-decoration:none;
}

.art-img {
    margin-right:0;
}

figure {
    margin:0;
}
    

/* Footer
---------------------------------------------------------*/

footer {
    border-top:#eee solid 2px;
}

footer h4 {
    font-size:1.4rem;
    letter-spacing:0;
}

footer a {
    text-decoration:none;
}

footer svg.fa-icon {
    fill:#000;
    height:calc(1 * var(--scale-factor)rem);
}

footer svg.fa-icon:hover {
    fill: var(--link-hover-color);
}

/*Google maps*/
.map-canvas {
    height: 30vh;
}

.map-canvas img {
    max-width: none;
}
 
/*Intendit footer*/
.created-by {
}

.intenditfooter {
    margin:0;
    font-size:0.8rem;
}


/* sticky footer */
.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 0 auto;
    min-height: 1%; /* IE fix */
}

header, footer {
    flex: none;
}

footer .contactinfo p {
    margin:0;
}


/* general
---------------------------------------------------------*/

.boltform textarea {
    min-height:120px;
}

input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"], textarea {
    width: 100%;
    color:#222;
}

button[type=submit], input[type=submit], .button {
    margin:0;
    background:var(--topmenu-hover-color);
    border:var(--topmenu-hover-color) solid 1px;
    color:white;
}

button[type=submit]:hover, input[type=submit]:hover, .button:hover {
    background: black;
    border:black solid 1px;
    color:white;
}


img {
    max-width: 100%;
    height: auto;
}

.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

.container {
    max-width: var(--container-max-width);
    padding: 0.5em;
    width: 100%;
}

html {
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    height:100%;
}

body {
    font-family: var(--base-font-family);
    font-weight: 400;
    color: var(--text-color);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

h1, h2, h3, h4, h5, h6, .logo a {
    font-family: var(--heading-font-family);
    color: var(--heading-color);
    margin:0 0 0.3em 0;
    line-height:normal;
}

h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
}

.homepage h1 {
    font-size: calc(1 * var(--scale-factor)rem);
}

h2 {
    font-size: calc(1 * var(--scale-factor)rem);
}

h3 {
    font-size: 1.2rem;
}

a {
    color: var(--link-color);
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

a:hover {
    color: var(--link-hover-color);
}

.markerLabels{
    overflow:visible !important;
}

.fa-map-marker{
    background: url(/extensions/local/intendit/intenditExt/web/black/map-marker.svg);
    background-size: cover;
    padding: 10px;
    background-position: center;
}


/* Media Queries
---------------------------------------------------------*/
 
@media (max-width: var(--breakpoint-m)) {
    .menu.show, .menu {
        background:#fff !important;
        box-shadow: 0 4px 2px -2px gray;
    }
    .topmenu {
        margin-top:0;
        background:#80D321;
    }
    .topmenu .container {
        padding:0;
    }
    #menu-icon:before, #menu-icon:after, #menu-icon span {
        border-color:white;
    }
    .navtext {
        margin-top:0.5em;
        color:white;
    }
}

@media (max-width: var(--breakpoint-s)) {
}

@media (max-width: var(--breakpoint-xs)) {
}


[data-clean] span{font-size: inherit !important; font-family: inherit !important; font-style: inherit !important;}
.embedly-embed {border:none;overflow:hidden;}
